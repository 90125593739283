import { BiddableListing } from '@frontend/api';
import { formatNumberToUS } from '@frontend/utils';
import { Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { useIntl } from 'react-intl';
import {
  BiddableListingDetailsDataItemLabelWrapper,
  BiddableListingDetailsDataItemListWrapper,
  BiddableListingDetailsDataItemWrapper,
  BiddableListingDetailsHeadingWrapper,
  BiddableListingDetailsWrapper,
} from './BiddableListingDetails.css';
import { BiddableListingDetailsDataItemProps } from './BiddableListingDetails.types';
import ArrowUpDownLineIcon from 'remixicon-react/ArrowUpDownLineIcon';
import MapPin2LineIcon from 'remixicon-react/MapPin2LineIcon';
import RecycleLineIcon from 'remixicon-react/RecycleLineIcon';

const BiddableListingCard = ({
  metalType,
  productDescription,
  isriGrade,
  quantity,
  materialFromCountry,
}: BiddableListing) => {
  const intl = useIntl();

  const BiddableListingCardDataItem = ({
    icon,
    label,
    value,
  }: BiddableListingDetailsDataItemProps) => {
    return (
      <BiddableListingDetailsDataItemWrapper>
        <BiddableListingDetailsDataItemLabelWrapper>
          {icon}
          <Typography variant="p2">{label}</Typography>
        </BiddableListingDetailsDataItemLabelWrapper>
        <Typography variant="p4">{value ?? '-'}</Typography>
      </BiddableListingDetailsDataItemWrapper>
    );
  };

  return (
    <BiddableListingDetailsWrapper>
      <BiddableListingDetailsHeadingWrapper>
        <Typography variant="h5">
          {capitalize(
            intl.formatMessage({
              id: `customer-platform.api.${metalType}`,
              defaultMessage: '-',
            }),
          )}
        </Typography>
        <Typography variant="p3">{productDescription || '-'}</Typography>
      </BiddableListingDetailsHeadingWrapper>
      <BiddableListingDetailsDataItemListWrapper>
        <BiddableListingCardDataItem
          icon={<RecycleLineIcon size={16} />}
          label={intl.formatMessage({
            id: 'customer-platform.bidding.biddable-listing-card.data-item.isri_grade',
          })}
          value={intl.formatMessage({
            id: `customer-platform.api.${isriGrade}`,
            defaultMessage: '-',
          })}
        />
        <BiddableListingCardDataItem
          icon={<ArrowUpDownLineIcon size={16} />}
          label={intl.formatMessage({
            id: 'customer-platform.bidding.biddable-listing-card.data-item.quantity',
          })}
          value={`${formatNumberToUS(quantity)} ${intl.formatMessage({ id: 'customer-platform.bidding.biddable-listing-card.data-item.weight-unit' })}`}
        />
        <BiddableListingCardDataItem
          icon={<MapPin2LineIcon size={16} />}
          label={intl.formatMessage({
            id: 'customer-platform.bidding.biddable-listing-card.data-item.material_from_country',
          })}
          value={
            materialFromCountry !== ''
              ? intl.formatMessage({
                  id: `customer-platform.api.country.${materialFromCountry}`,
                  defaultMessage: materialFromCountry,
                })
              : '-'
          }
        />
      </BiddableListingDetailsDataItemListWrapper>
    </BiddableListingDetailsWrapper>
  );
};

export default BiddableListingCard;
