import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import {
  AccordionSummaryFullWidthRowSt,
  MUIAccordionSummarySt,
} from './AccordionSummary.css';
import { AccordionSummaryProps } from './AccordionSummary.types';
import { useTheme } from '@mui/material';
import { ReactNode } from 'react';

export function AccordionSummary({
  children,
  showActionButton,
  expandIconSize = 16,
  ...rest
}: AccordionSummaryProps) {
  const theme = useTheme();

  return (
    <MUIAccordionSummarySt
      {...rest}
      expandIcon={
        showActionButton ? (
          <ArrowDownSLineIcon
            size={expandIconSize}
            color={theme.palette.neutral[500]}
          />
        ) : undefined
      }
    >
      {children}
    </MUIAccordionSummarySt>
  );
}

export function AccordionSummaryFullWidthRow({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <AccordionSummaryFullWidthRowSt>{children}</AccordionSummaryFullWidthRowSt>
  );
}
