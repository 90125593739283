import { useState } from 'react';
import { useDownloadAllContainerImage } from '@frontend/api';
import { Translate } from '@frontend/translation';
import { Button } from '@frontend/ui-elements';
import { DownloadAllContainerImagesProps } from './DownloadAllContainerImages.types';
import { useIntl } from 'react-intl';

export default function DownloadAllContainerImages({
  containerId,
  imageList,
  containerNumber,
}: DownloadAllContainerImagesProps) {
  const intl = useIntl();
  const { downloadAllContainerImage } = useDownloadAllContainerImage();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const id =
    'customer-platform.request-details.order-list.container-drawer.images.download-all';
  const handleDownloadAll = async () => {
    setIsDownloading(true);
    await downloadAllContainerImage(containerId, containerNumber);
    setIsDownloading(false);
  };

  return (
    imageList.length && (
      <Button
        buttonType="linked"
        id={id}
        size="large"
        disabled={isDownloading}
        onClick={handleDownloadAll}
        isSubmitting={isDownloading}
        submittingCaption={intl.formatMessage({
          id: 'customer-platform.shared.components.button.submitting-caption',
        })}
      >
        <Translate id={id} />
      </Button>
    )
  );
}
