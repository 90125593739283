import { useGetOrderList } from '@frontend/api';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import {
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { head } from 'lodash';
import { useEffect, useRef } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  ListWrapper,
  LoaderWrapper,
  MainWrapper,
} from './OrderList.css';
import { IOrderList } from './OrderList.types';
import { useGetDistanceToTop } from '../../../../app/shared/utils/useGetDistanceToTop/useGetDistanceToTop';
import EmptyOrder from '../../../../app/shared/components/EmptyOrder/EmptyOrder';
import OrderCard from './OrderCard/OrderCard';
import RequestDocuments from './DocumentList/RequestDocuments/RequestDocuments';

export default function OrderList({ request }: IOrderList) {
  const { status: getOrderListStatus, data: orderListResponse } =
    useGetOrderList(request.uuid);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const { orderId } = useParams();
  const navigate = useNavigate();

  const refHTMLElement = useRef<HTMLDivElement>(null);
  const distanceToTop = useGetDistanceToTop({ elementRef: refHTMLElement });

  useEffect(() => {
    if (!isMobile && !orderId && orderListResponse?.data.length) {
      const firstOrder = head(orderListResponse.data);

      if (firstOrder) {
        const urlDestination = generatePath(PLATFORM_ROUTES.orderDetails, {
          uuid: request.uuid,
          orderId: firstOrder.uuid,
        });
        navigate(urlDestination, { replace: true });
      }
    }
    // Preventing navigate and orderID to be in the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, orderListResponse, request.uuid]);

  const handleChange = (clickedOrderUuid: string) => {
    const urlDestination =
      orderId === clickedOrderUuid
        ? generatePath(PLATFORM_ROUTES.requestDetails, {
            uuid: request.uuid,
          })
        : generatePath(PLATFORM_ROUTES.orderDetails, {
            uuid: request.uuid,
            orderId: clickedOrderUuid,
          });
    navigate(urlDestination, { replace: true });
  };

  if (getOrderListStatus === 'success') {
    if (orderId && isMobile) {
      const currentOrder = orderListResponse.data.find(
        order => order.uuid === orderId,
      );
      if (currentOrder) {
        return (
          <OrderCard
            order={currentOrder}
            isOrderSelected={currentOrder.uuid === orderId}
          />
        );
      }
    }
  }

  return (
    <MainWrapper ref={refHTMLElement} distance={distanceToTop}>
      <Container>
        <Typography variant={isMobile ? 'h4' : 'h5'}>
          <Translate
            id="customer-platform.request-details.order-list.order-request-number"
            values={{
              REQUEST_NUMBER_ID: request.requestNumberId,
            }}
          />
        </Typography>
        <RequestDocuments request={request} />
        <ListWrapper>
          {getOrderListStatus === 'pending' ? (
            <LoaderWrapper>
              <CircularProgress size={160} />
            </LoaderWrapper>
          ) : getOrderListStatus === 'success' ? (
            orderListResponse?.data.length ? (
              orderListResponse?.data.map(order => (
                <OrderCard
                  key={order.uuid}
                  order={order}
                  isOrderSelected={order.uuid === orderId}
                  onChange={() => handleChange(order.uuid)}
                />
              ))
            ) : (
              <EmptyOrder />
            )
          ) : (
            <EmptyOrder />
          )}
        </ListWrapper>
      </Container>
    </MainWrapper>
  );
}
