import { ORDER_STATUS } from '@frontend/api';
import { Stage, StatusBar } from '@frontend/ui-elements';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {} from '../OrderOverview.css';
import { IOrderOverview } from '../OrderOverview.types';
import { OrderStatusBarWrapper } from './OrderStatusBar.css';

export default function OrderStatusBar({
  order,
  isMobile = false,
  isOrderSelected = false,
}: IOrderOverview) {
  const [isStatusBarOpen, setIsStatusBarOpen] = useState(false);
  const intl = useIntl();
  const stages: Stage[] = [
    {
      order: 0,
      label: intl.formatMessage({
        id: 'customer-platform.api.order.status.preparing',
      }),
    },
    {
      order: 1,
      label: intl.formatMessage({
        id: 'customer-platform.api.order.status.loading',
      }),
    },
    {
      order: 2,
      label: intl.formatMessage({
        id: 'customer-platform.api.order.status.in_transit',
      }),
    },
    {
      order: 3,
      label: intl.formatMessage({
        id: 'customer-platform.api.order.status.arrived',
      }),
    },
  ];

  const handleStatusBarOpen = () => {
    setIsStatusBarOpen(!isStatusBarOpen);
  };

  const activeStep = ORDER_STATUS.indexOf(order.status);

  if (isMobile && isOrderSelected) {
    return (
      <OrderStatusBarWrapper>
        <StatusBar
          activeStep={activeStep}
          onClick={handleStatusBarOpen}
          open={isStatusBarOpen}
          stages={stages}
        />
      </OrderStatusBarWrapper>
    );
  }
  return (
    <OrderStatusBarWrapper>
      <StatusBar
        activeStep={activeStep}
        onClick={handleStatusBarOpen}
        open={isStatusBarOpen}
        stages={stages}
        isSimplified={isMobile}
      />
    </OrderStatusBarWrapper>
  );
}
