import {
  DESTINATION_ADDRESS_INCOTERMS,
  IBiddingSubmissionData,
  IBiddingSubmissionDataKeys,
  INCOTERMS,
  SHIPPING_PORT_INCOTERMS,
  useCreateBidding,
} from '@frontend/api';
import { Translate } from '@frontend/translation';
import {
  Button,
  CheckBox,
  Link,
  Select,
  TextArea,
  TextField,
} from '@frontend/ui-elements';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectChangeEvent, Typography, useTheme } from '@mui/material';
import { head, identity, pickBy } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  ControllerRenderProps,
  SubmitHandler,
  useController,
  useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { buildSelectDataByOptionsAndNamespace } from '../../../app/shared/components/Forms/utils/utils';
import {
  MAX_NUMBER_AFTER_DECIMAL,
  MIN_QUANTITY_REQUIRED_MT,
} from '../../../app/shared/components/Forms/Forms.const';
import {
  CaptionBox,
  FieldsWrapper,
  FormBox,
  FormContainer,
} from './BiddingForm.css';
import {
  BiddingFormProps,
  IBiddingFormSchemaTranslations,
} from './BiddingForm.types';
import getBiddingFormSchema from './BiddingForm.utils';
import DatePicker from '../../../app/shared/components/ui-csr/DatePicker/DatePicker';
import FormType from '../../../app/shared/components/Forms/utils/FormType.types';
import { useFormTracking } from '../../../app/shared/components/Forms/utils/useFormTracking';
import SuccessModal from '../../../app/shared/components/Modals/SuccessModal/SuccessModal';

const BiddingForm = ({ uuid, onFormSuccess }: BiddingFormProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const formData = useRef<IBiddingSubmissionData>({});
  const { mutate, status, error } = useCreateBidding(uuid);

  const maxNumbersAfterDecimal = intl.formatMessage(
    {
      id: 'customer-platform.shared.components.forms.errors.number.max_numbers_after_decimal',
    },
    { MAX_NUMBER_AFTER_DECIMAL: MAX_NUMBER_AFTER_DECIMAL.toString() },
  );
  const minQuantityRequired = intl.formatMessage(
    {
      id: 'customer-platform.shared.components.forms.errors.quantify.min-quantity-required',
    },
    { MIN_QUANTITY_REQUIRED_MT: MIN_QUANTITY_REQUIRED_MT.toString() },
  );
  const required = intl.formatMessage({
    id: 'customer-platform.shared.components.forms.errors.required',
  });
  const requiredCheckbox = intl.formatMessage({
    id: 'customer-platform.biddable-listing-page.bidding-form.errors.checkbox-required',
  });

  const biddingFormSchema = getBiddingFormSchema({
    required,
    requiredCheckbox,
    maxNumbersAfterDecimal,
    minQuantityRequired,
  } as IBiddingFormSchemaTranslations);

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    register,
    reset,
    resetField,
    setError,
    watch,
  } = useForm<FormType<IBiddingSubmissionData>>({
    defaultValues: {
      price: '',
      paymentTerms: '',
      quantity: '',
      incoterms: '',
      shippingPort: '',
      destinationAddress: '',
      validity: '',
      additionalComments: '',
      termsAgreed: false,
    },
    resolver: yupResolver<FormType<IBiddingSubmissionData>>(biddingFormSchema),
  });

  const formId = 'bidding-form.form';
  const incotermsData = buildSelectDataByOptionsAndNamespace(INCOTERMS, intl);
  const selectedIncoterm = watch('incoterms');

  const { field: incotermsField } = useController({
    name: 'incoterms',
    control,
  });
  const { field: validityField } = useController({
    name: 'validity',
    control,
  });
  const { field: termsAgreedField } = useController({
    name: 'termsAgreed',
    control,
  });

  const handleChange =
    (field: ControllerRenderProps) => (event: SelectChangeEvent<unknown>) => {
      field.onChange(event.target.value);
    };

  const handleDatePickerChange = (value: Date) => {
    validityField.onChange(value);
  };

  const handleCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    termsAgreedField.onChange(event.target.checked);
  };

  const onSubmit: SubmitHandler<
    FormType<IBiddingSubmissionData>
  > = biddingData => {
    formData.current = pickBy(biddingData, identity);
    mutate(formData.current);
  };

  useEffect(() => {
    const formErrors = error?.response?.data;
    if (formErrors !== undefined) {
      Object.keys(formErrors).map((field: string) =>
        setError(field as IBiddingSubmissionDataKeys, {
          type: 'manual',
          message: formErrors[field as IBiddingSubmissionDataKeys][0] as string,
        }),
      );
    }
  }, [error, setError]);

  useEffect(() => {
    if (selectedIncoterm) {
      DESTINATION_ADDRESS_INCOTERMS.includes(selectedIncoterm) &&
        resetField('shippingPort');
      SHIPPING_PORT_INCOTERMS.includes(selectedIncoterm) &&
        resetField('destinationAddress');
    }
  }, [selectedIncoterm, reset, resetField]);

  useFormTracking({ formId, formErrors, status });

  useEffect(() => {
    if (status === 'success') {
      reset();
      onFormSuccess && onFormSuccess();
      setIsModalOpen(true);
    }
  }, [onFormSuccess, reset, status]);

  return (
    <>
      <FormContainer>
        <CaptionBox>
          <Typography variant="p1">
            <Translate id="customer-platform.biddable-listing-page.bidding-form.paragraph" />
          </Typography>
          <Typography component="p" variant="caption2">
            <Translate id="customer-platform.biddable-listing-page.bidding-form.caption" />
          </Typography>
        </CaptionBox>
        <form
          id={formId}
          name={formId}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <FormBox>
            <FieldsWrapper>
              <TextField
                id="price"
                type="number"
                label={
                  <Translate id="customer-platform.biddable-listing-page.bidding-form.textfield.price" />
                }
                {...register('price')}
                error={!!formErrors?.price}
                helperText={formErrors?.price?.message}
                required
                formId={formId}
              />
              <TextField
                id="payment-terms"
                label={
                  <Translate id="customer-platform.biddable-listing-page.bidding-form.textfield.payment-terms" />
                }
                {...register('paymentTerms')}
                error={!!formErrors?.paymentTerms}
                helperText={formErrors?.paymentTerms?.message}
                required
                formId={formId}
              />
              <TextField
                id="quantity"
                type="number"
                label={
                  <Translate id="customer-platform.biddable-listing-page.bidding-form.textfield.quantity" />
                }
                {...register('quantity')}
                error={!!formErrors?.quantity}
                helperText={formErrors?.quantity?.message}
                required
                formId={formId}
              />
              <Select
                id="incoterms"
                label={
                  <Translate id="customer-platform.biddable-listing-page.bidding-form.select.incoterms" />
                }
                options={incotermsData.options}
                value={incotermsField.value}
                onChange={handleChange(incotermsField as ControllerRenderProps)}
                error={!!formErrors?.incoterms}
                helperText={formErrors?.incoterms?.message}
                required
                formId={formId}
              />
              {selectedIncoterm &&
                SHIPPING_PORT_INCOTERMS.includes(selectedIncoterm) && (
                  <TextField
                    id="shipping-port"
                    label={
                      <Translate id="customer-platform.biddable-listing-page.bidding-form.textfield.shipping-port" />
                    }
                    {...register('shippingPort')}
                    error={!!formErrors?.shippingPort}
                    helperText={formErrors?.shippingPort?.message}
                    required
                    formId={formId}
                  />
                )}
              {selectedIncoterm &&
                DESTINATION_ADDRESS_INCOTERMS.includes(selectedIncoterm) && (
                  <TextField
                    id="destination-address"
                    label={
                      <Translate id="customer-platform.biddable-listing-page.bidding-form.textfield.destination-address" />
                    }
                    {...register('destinationAddress')}
                    error={!!formErrors?.destinationAddress}
                    helperText={formErrors?.destinationAddress?.message}
                    required
                    formId={formId}
                  />
                )}
              <DatePicker
                id="validity"
                format="dd/MM/yyyy"
                label={
                  <Translate id="customer-platform.biddable-listing-page.bidding-form.datepicker.label" />
                }
                value={validityField.value}
                onChange={handleDatePickerChange}
                error={!!formErrors?.validity}
                helperText={formErrors?.validity?.message}
                disablePast
                formId={formId}
              />
              <TextArea
                id="additional-comments"
                placeholder={intl.formatMessage({
                  id: 'customer-platform.biddable-listing-page.bidding-form.textarea.additional-comments',
                })}
                {...register('additionalComments')}
                error={!!formErrors?.additionalComments}
                helperText={formErrors?.additionalComments?.message}
                formId={formId}
                minRows={3}
                maxRows={6}
              />
              <CheckBox
                id="terms-agreed"
                label={
                  <Translate
                    id="customer-platform.biddable-listing-page.bidding-form.checkbox.terms-text"
                    values={{
                      a: (chunks: string[]) => (
                        <Link
                          id="bidding-form.button.go-to-terms"
                          color={theme.palette.primary[500]}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${import.meta.env.VITE_STATIC_APP_URL}/terms-of-service`}
                        >
                          {head(chunks)}
                        </Link>
                      ),
                    }}
                  />
                }
                value={termsAgreedField.value as boolean}
                onChange={handleCheckBoxChange}
                error={!!formErrors?.termsAgreed}
                helperText={formErrors?.termsAgreed?.message}
                formId={formId}
                size="small"
                required
              />
            </FieldsWrapper>
            <Button
              buttonType="primary"
              id="bidding-form.button.submit"
              size="large"
              type="submit"
              isSubmitting={status === 'pending'}
            >
              <Translate id="customer-platform.biddable-listing-page.bidding-form.button.submit" />
            </Button>
          </FormBox>
        </form>
      </FormContainer>
      {isModalOpen && (
        <SuccessModal
          title={intl.formatMessage({
            id: 'customer-platform.biddable-listing-page.bidding-form.success-modal.title',
          })}
          text={intl.formatMessage({
            id: 'customer-platform.biddable-listing-page.bidding-form.success-modal.text',
          })}
          primaryCta={{
            text: intl.formatMessage({
              id: 'customer-platform.biddable-listing-page.bidding-form.success-modal.cta.primary',
            }),
            onClick: () => setIsModalOpen(false),
          }}
          secondaryCta={{
            text: intl.formatMessage({
              id: 'customer-platform.biddable-listing-page.bidding-form.success-modal.cta.secondary',
            }),
            onClick: () => {
              window.location.href = import.meta.env.VITE_STATIC_APP_URL;
            },
          }}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default BiddingForm;
