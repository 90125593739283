import {
  BiddableListing,
  IBuyer,
  USER_ROLE_TYPE_ENUM,
  useGetBiddableListingData,
  useRegisterUserRequest,
} from '@frontend/api';
import { LOGIN_LINK_SOURCE, useAuth } from '@frontend/auth';
import { convertNumberToWords } from '@frontend/utils';
import { Translate } from '@frontend/translation';
import {
  Button,
  InnerTypography,
  PageWrapper,
  ToastAlert,
} from '@frontend/ui-elements';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import { ReactElement, useEffect, useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { identity, pickBy } from 'lodash';
import {
  BiddableListingCardWrapper,
  HeadingWrapper,
  LoaderWrapper,
  PrivatePageLeftColumn,
  PrivatePageRightColumn,
  PrivatePageWrapper,
  PublicPageLeftColumn,
  PublicPageRightColumn,
  PublicPageWrapper,
  Separator,
  SeparatorWrapper,
  SubheadingWrapper,
} from './BiddableListingPage.css';
import { RE_LOGIN_SOURCE } from '../LoginValidation/LoginValidation.const';
import useModal from '../../app/shared/components/Modals/useModal/useModal';
import BiddableListingDetails from './BiddableListingDetails/BiddableListingDetails';
import BiddableListingImageList from './BiddableListingImageList/BiddableListingImageList';
import BiddingForm from './BiddingForm/BiddingForm';
import BiddingOnboardingSteps from './BiddingOnboardingSteps/BiddingOnboardingSteps';
import CustomerForm from '../../app/shared/components/Forms/CustomerForm/CustomerForm';
import ErrorSection from '../../app/shared/components/ErrorSection/ErrorSection';
import FormType from '../../app/shared/components/Forms/utils/FormType.types';
import LoginLinkExpiredModal from '../../app/shared/components/Modals/LoginLinkExpiredModal/LoginLinkExpiredModal';
import LoginModal from '../../app/shared/components/Modals/LoginModal/LoginModal';
import PlatformHeader from '../../app/shared/components/PlatformHeader/PlatformHeader';
import SuccessModal from '../../app/shared/components/Modals/SuccessModal/SuccessModal';
import { HEADER_VARIANTS } from '../../app/shared/components/PlatformHeader/PlatformHeader.types';

export function BiddableListingPage() {
  const { isAuthenticated, isCurrentUserBuyer, isCurrentUserSeller } =
    useAuth();
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { uuid: listingId } = useParams();
  const theme = useTheme();

  const {
    openLoginModal,
    openLinkExpiredModal,
    openLinkLoginModal,
    openSuccessModal,
    closeModal,
    isLoginModalOpen,
    isSuccessModalOpen,
    isLinkExpiredModalOpen,
    isLinkLoginModalOpen,
  } = useModal();

  const {
    status: getBiddableListingDataStatus,
    data: getBiddableListingDataResponse,
    error: getBiddableListingDataError,
    refetch: refetchBiddableListingData,
  } = useGetBiddableListingData(listingId as string);

  const {
    mutate,
    status: registerUserRequestStatus,
    error: registerUserRequestError,
  } = useRegisterUserRequest({
    redirectUrl: location.pathname,
    sourceUrl: location.pathname,
  });

  const isBuyer = isAuthenticated && isCurrentUserBuyer;
  const isSeller = isAuthenticated && isCurrentUserSeller;
  const listing = getBiddableListingDataResponse?.data as BiddableListing;
  const formData = useRef<FormType<IBuyer>>();

  const handleCustomerFormSubmit: SubmitHandler<
    FormType<IBuyer>
  > = userData => {
    formData.current = {
      ...pickBy(userData, identity),
      role: USER_ROLE_TYPE_ENUM.BUYER,
    };
    mutate(formData.current as IBuyer);
  };

  useEffect(() => {
    const queryParamsSource = new URLSearchParams(location.search).get(
      'source',
    );
    if (queryParamsSource === RE_LOGIN_SOURCE) {
      openLinkExpiredModal();
    } else if (queryParamsSource === LOGIN_LINK_SOURCE) {
      openLinkLoginModal();
    }
  }, [location, openLinkExpiredModal, openLinkLoginModal]);

  useEffect(() => {
    if (registerUserRequestStatus === 'success') {
      openSuccessModal();
    }
  }, [registerUserRequestStatus, openSuccessModal]);

  return (
    <>
      <PlatformHeader
        variant={isBuyer ? HEADER_VARIANTS.BUYER : HEADER_VARIANTS.GUEST}
      />
      <PageWrapper>
        <>
          {getBiddableListingDataStatus === 'success' &&
            (isBuyer ? (
              <PrivatePageWrapper>
                <PrivatePageLeftColumn>
                  <BiddableListingDetails {...listing} />
                  <BiddableListingImageList
                    uuid={listingId as string}
                    productImages={listing?.productImages}
                  />
                </PrivatePageLeftColumn>
                <PrivatePageRightColumn>
                  <Typography variant="h4">
                    <Translate id="customer-platform.biddable-listing-page.form-title" />
                  </Typography>
                  {(listing?.submittedBidsCount ?? 0) > 0 && (
                    <ToastAlert
                      title={
                        intl.formatMessage(
                          {
                            id: 'customer-platform.biddable-listing-page.alert.submitted-bids-count',
                          },
                          {
                            BIDS_COUNT: (
                              <u>
                                {convertNumberToWords(
                                  listing?.submittedBidsCount ?? 0,
                                )}
                              </u>
                            ),
                          },
                        ) as ReactElement
                      }
                      severity="success"
                    />
                  )}
                  <BiddingForm
                    uuid={listingId as string}
                    onFormSuccess={refetchBiddableListingData}
                  />
                </PrivatePageRightColumn>
              </PrivatePageWrapper>
            ) : isSeller ? (
              <ErrorSection
                title={intl.formatMessage({
                  id: 'customer-platform.biddable-listing-page.seller-error.title',
                })}
                description={intl.formatMessage({
                  id: 'customer-platform.biddable-listing-page.seller-error.description',
                })}
                btnText={intl.formatMessage({
                  id: 'customer-platform.biddable-listing-page.seller-error.button',
                })}
                onClick={() => {
                  navigate(PLATFORM_ROUTES.sellerDashboard);
                }}
              />
            ) : (
              <PublicPageWrapper>
                <PublicPageLeftColumn>
                  <HeadingWrapper>
                    <Typography variant="h2">
                      <InnerTypography
                        message={intl.formatMessage({
                          id: 'customer-platform.biddable-listing-page.title',
                        })}
                        variant="h2"
                        color={theme.palette.primary[500]}
                      />
                    </Typography>
                    <SubheadingWrapper>
                      <Typography variant="p1">
                        <Translate id="customer-platform.biddable-listing-page.subtitle.text" />
                      </Typography>
                      <Button
                        buttonType="linked"
                        id="biddable-listing-page.button.open-login"
                        size="large"
                        onClick={openLoginModal}
                      >
                        <Translate id="customer-platform.biddable-listing-page.subtitle.button.open-login" />
                      </Button>
                      {(isLoginModalOpen || isLinkLoginModalOpen) && (
                        <LoginModal
                          isLoginLink={isLinkLoginModalOpen}
                          isOpen={true}
                          onClose={closeModal}
                          role={USER_ROLE_TYPE_ENUM.BUYER}
                          sourceUrl={location.pathname}
                          redirectUrl={location.pathname}
                        />
                      )}
                      {isLinkExpiredModalOpen && (
                        <LoginLinkExpiredModal
                          onClose={closeModal}
                          onClickOpenLoginModal={openLoginModal}
                        />
                      )}
                    </SubheadingWrapper>
                  </HeadingWrapper>
                  <SeparatorWrapper>
                    <Separator />
                    <Typography variant="p1">
                      <Translate id="customer-platform.biddable-listing-page.separator" />
                    </Typography>
                    <Separator />
                  </SeparatorWrapper>
                  <CustomerForm
                    onSubmit={handleCustomerFormSubmit}
                    status={registerUserRequestStatus}
                    errors={registerUserRequestError?.response?.data}
                    submitTranslationId="customer-platform.biddable-listing-page.customer-form.button.submit"
                  />
                </PublicPageLeftColumn>
                <PublicPageRightColumn>
                  <BiddableListingCardWrapper>
                    <BiddableListingDetails {...listing} />
                  </BiddableListingCardWrapper>
                  <BiddingOnboardingSteps />
                </PublicPageRightColumn>
                {isSuccessModalOpen && (
                  <SuccessModal
                    title={intl.formatMessage({
                      id: 'customer-platform.biddable-listing-page.customer-form.success-modal.title',
                    })}
                    text={intl.formatMessage({
                      id: 'customer-platform.biddable-listing-page.customer-form.success-modal.text',
                    })}
                    onClose={closeModal}
                  />
                )}
              </PublicPageWrapper>
            ))}

          {getBiddableListingDataStatus === 'pending' && (
            <LoaderWrapper>
              <CircularProgress size={120} data-testid="CircularProgress" />
            </LoaderWrapper>
          )}

          {getBiddableListingDataError?.response?.status === 404 && (
            <ErrorSection
              title={intl.formatMessage({
                id: 'customer-platform.biddable-listing-page.error.title',
              })}
              description={intl.formatMessage({
                id: 'customer-platform.biddable-listing-page.error.description',
              })}
              btnText={intl.formatMessage({
                id: 'customer-platform.biddable-listing-page.error.button',
              })}
              onClick={() => {
                window.location.href = import.meta.env.VITE_STATIC_APP_URL;
              }}
            />
          )}
        </>
      </PageWrapper>
    </>
  );
}

export default BiddableListingPage;
