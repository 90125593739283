import { formatNumberToUS } from '@frontend/utils';
import { Translate } from '@frontend/translation';
import { AccordionSummary, Button, EmptySpace } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ButtonWrapper,
  ListingAccordion,
  ListingAccordionDetails,
  ListingDetails,
  ListingDetailsItem,
  ListingNoAndDateWrapper,
  ListingOverview,
} from './ListingCard.css';
import { IListingCardProps } from './ListingCard.types';
import { formatDateWithDots } from '../../../app/shared/utils/date.utils';
import ArrowUpDownLineIcon from 'remixicon-react/ArrowUpDownLineIcon';
import FoldersLineIcon from 'remixicon-react/FoldersLineIcon';
import MapPinLineIcon from 'remixicon-react/MapPinLineIcon';
import MoneyDollarCircleLineIcon from 'remixicon-react/MoneyDollarCircleLineIcon';
import NullableCardDetailsItem from '../../../app/shared/components/Cards/NullableCardDetailsItem/NullableCardDetailsItem';
import RecycleLineIcon from 'remixicon-react/RecycleLineIcon';
import StatusPill from '../../../app/shared/components/Cards/StatusPill/StatusPill';
import { useCardScroll } from '../../../app/shared/components/useCardScroll/useCardScroll';

export default function ListingCard({
  listing: {
    status,
    publicId,
    listingNumberId,
    quantity,
    metalType,
    incoterms,
    price,
    shippingFrom,
    createdDate,
    cost,
  },
  expanded,
  onClick,
}: IListingCardProps) {
  const theme = useTheme();
  const isDesktopOrBigger = useMediaQuery(theme.breakpoints.up('desktop'));
  const isTabletOrSmaller = !isDesktopOrBigger;
  const { cardRef } = useCardScroll({
    enableScroll: expanded && isDesktopOrBigger,
  });

  return (
    <ListingAccordion
      expanded={expanded}
      id={`listing-card.accordion.${publicId}`}
      onClick={isDesktopOrBigger && !expanded ? onClick : undefined}
      square
      ref={cardRef}
    >
      <AccordionSummary showActionButton={isDesktopOrBigger}>
        <ListingOverview>
          <ListingNoAndDateWrapper>
            <Typography variant="h6">
              <Translate
                id="customer-platform.listing-list.listing-card.order-number"
                values={{
                  LISTING_NUMBER_ID: listingNumberId,
                }}
              />
            </Typography>
            <Typography variant="caption4" component="span">
              <Translate
                id="customer-platform.listing-list.listing-card.created-date"
                values={{
                  CREATION_DATE: formatDateWithDots(createdDate),
                }}
              />
            </Typography>
          </ListingNoAndDateWrapper>
          <StatusPill status={status} />
        </ListingOverview>
      </AccordionSummary>
      <ListingAccordionDetails>
        <ListingDetails>
          <ListingDetailsItem aria-label="weight-info">
            <ArrowUpDownLineIcon size={16} />
            <Typography variant="caption1" component="span">
              <Translate id="customer-platform.listing-list.listing-card.weight" />
            </Typography>
            <NullableCardDetailsItem data={quantity}>
              <Typography variant="caption2" component="span">
                {formatNumberToUS(quantity as number)}
                <EmptySpace />
                <Translate id="customer-platform.listing-list.listing-card.weight-unit" />
              </Typography>
            </NullableCardDetailsItem>
          </ListingDetailsItem>
          <ListingDetailsItem aria-label="metal-type-info">
            <RecycleLineIcon size={16} />
            <Typography variant="caption1" component="span">
              <Translate id="customer-platform.listing-list.listing-card.metal-type" />
            </Typography>
            <Typography variant="caption2" component="span">
              <Translate id={`customer-platform.api.${metalType}`} />
            </Typography>
          </ListingDetailsItem>
          <ListingDetailsItem aria-label="incoterm-info">
            <FoldersLineIcon size={16} />
            <Typography variant="caption1" component="span">
              <Translate id="customer-platform.listing-list.listing-card.incoterms" />
            </Typography>
            <NullableCardDetailsItem data={incoterms}>
              <Typography variant="caption2" component="span">
                <Translate id={`customer-platform.api.${incoterms}`} />
              </Typography>
            </NullableCardDetailsItem>
          </ListingDetailsItem>
          <ListingDetailsItem aria-label="shipping-from-info">
            <MapPinLineIcon size={16} />
            <Typography variant="caption1" component="span">
              <Translate id="customer-platform.listing-list.listing-card.shipping-from" />
            </Typography>
            <NullableCardDetailsItem data={shippingFrom}>
              <Typography variant="caption2" component="span">
                {shippingFrom}
              </Typography>
            </NullableCardDetailsItem>
          </ListingDetailsItem>
          <ListingDetailsItem aria-label="cost-info">
            <MoneyDollarCircleLineIcon size={16} />
            <Typography variant="caption1" component="span">
              <Translate id="customer-platform.listing-list.listing-card.cost" />
            </Typography>
            <Typography variant="caption2" component="span">
              {cost ? (
                <>
                  {formatNumberToUS(cost as number)}
                  <EmptySpace />
                  <Translate id="customer-platform.listing-list.listing-card.cost-unit" />
                </>
              ) : (
                <Translate id="customer-platform.listing-list.listing-card.cost-unknown" />
              )}
            </Typography>
          </ListingDetailsItem>
        </ListingDetails>
        {isTabletOrSmaller && (
          <ButtonWrapper>
            <Button
              buttonType="primary"
              id={`listing-card.button.open-listing-${publicId}`}
              size="small"
              onClick={onClick}
            >
              <Translate id="customer-platform.listing-list.listing-card.cta" />
            </Button>
          </ButtonWrapper>
        )}
      </ListingAccordionDetails>
    </ListingAccordion>
  );
}
