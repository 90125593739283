import { formatNumberToUS } from '@frontend/utils';
import { Translate } from '@frontend/translation';
import { EmptySpace } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import {
  ContainerInformationWrapper,
  InformationItemsWrapper,
  InformationItemWrapper,
  MissingInfoWrapper,
} from './ContainerInformation.css';
import {
  ContainerInformationProps,
  InformationItemProps,
} from './ContainerInformation.types';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';
import { isNull, some, values } from 'lodash';

const isValueMissing = (value: string | number | null) => {
  return isNull(value) || value === '';
};

function ContainerInformation({
  containerNumber,
  ...rest
}: ContainerInformationProps) {
  const { sealNumber, netWeight, tare, total } = rest;

  const isAnyFieldMissing = some(values(rest), isValueMissing);

  const InformationItem = ({
    titleTranslationId,
    value,
    hasWeightUnit,
  }: InformationItemProps) => {
    const Unit = () => {
      if (hasWeightUnit && !isValueMissing(value)) {
        return (
          <>
            <EmptySpace />
            <Translate id="customer-platform.request-details.order-card.weight-unit" />
          </>
        );
      }
      return null;
    };

    return (
      <InformationItemWrapper>
        <Typography variant="p1" noWrap>
          <Translate id={titleTranslationId} />
        </Typography>
        <Typography variant="p3">
          {isValueMissing(value) ? (
            <Translate id="customer-platform.request-details.order-list.container-drawer.container-information.not-available" />
          ) : (
            formatNumberToUS(value as number | string)
          )}
          <Unit />
        </Typography>
      </InformationItemWrapper>
    );
  };

  const MissingInfoToast = () => (
    <MissingInfoWrapper>
      <InformationLineIcon size={24} />
      <Typography variant="p3">
        <Translate id="customer-platform.request-details.order-list.container-drawer.container-information.missing-info" />
      </Typography>
    </MissingInfoWrapper>
  );

  return (
    <ContainerInformationWrapper data-testid="container-information">
      <Typography variant="h5">
        <Translate
          id={
            'customer-platform.request-details.order-list.container-drawer.container-information.title'
          }
        />
      </Typography>
      {isAnyFieldMissing && <MissingInfoToast />}
      <InformationItemsWrapper>
        <InformationItem
          titleTranslationId="customer-platform.request-details.order-list.container-drawer.container-information.container-number"
          value={containerNumber}
        />
        <InformationItem
          titleTranslationId="customer-platform.request-details.order-list.container-drawer.container-information.seal-number"
          value={sealNumber}
        />
        <InformationItem
          titleTranslationId="customer-platform.request-details.order-list.container-drawer.container-information.net-weight"
          value={netWeight}
          hasWeightUnit
        />
        <InformationItem
          titleTranslationId="customer-platform.request-details.order-list.container-drawer.container-information.tare"
          value={tare}
          hasWeightUnit
        />
        <InformationItem
          titleTranslationId="customer-platform.request-details.order-list.container-drawer.container-information.total"
          value={total}
          hasWeightUnit
        />
      </InformationItemsWrapper>
    </ContainerInformationWrapper>
  );
}

export default ContainerInformation;
