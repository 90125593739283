import { MainContainer } from './EmptyDashboard.css';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Translate } from '@frontend/translation';
import { useMatch } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { ROUTE_NAMES } from '@frontend/utils';

export default function EmptyDashboard() {
  const theme = useTheme();
  const buyerDashboardRouteMatched =
    useMatch(PLATFORM_ROUTES.buyerDashboard) ||
    useMatch(`${PLATFORM_ROUTES.buyerDashboard}/*`);
  const currentVariantType = buyerDashboardRouteMatched
    ? ROUTE_NAMES.REQUEST
    : ROUTE_NAMES.LISTING;
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <MainContainer>
      <Typography variant={isMobile ? 'h4' : 'h2'}>
        <Translate id={`customer-platform.request-list.empty.heading`} />
      </Typography>
      <Typography variant={isMobile ? 'h5' : 'h4'}>
        <Translate
          id={`customer-platform.${currentVariantType}-list.empty.subtitle`}
        />
      </Typography>
    </MainContainer>
  );
}
