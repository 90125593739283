import { styled } from '@frontend/theme';
import {
  Accordion as BaseAccordion,
  AccordionDetails as BaseAccordionDetails,
} from '@frontend/ui-elements';
import { Box as MUIBox } from '@mui/material';

export const ButtonWrapper = styled(MUIBox)({
  display: 'flex',
  alignItems: 'flex-start',
});

export const ListingAccordion = styled(BaseAccordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    background: `${theme?.palette.common.white}`,
    padding: '20px 20px',
    border: `1px solid ${theme?.palette.primary[50]}`,
    [theme.breakpoints.up('desktop')]: {
      '&.Mui-expanded': {
        border: `1px solid ${theme?.palette.primary[500]}`,
      },
    },
  },
}));

export const ListingAccordionDetails = styled(BaseAccordionDetails)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const ListingOverview = styled(MUIBox)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
});

export const ListingNoAndDateWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  alignItems: 'start',
});

export const ListingDetails = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ListingDetailsItem = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  padding: '8px 0px',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
  ':not(:last-of-type)': {
    borderBottom: `1px solid ${theme?.palette.neutral[50]}`,
  },
}));

export const ItemTitleValueWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
});
