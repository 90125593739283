import { Typography } from '@mui/material';
import { formatNumberToUS } from '@frontend/utils';
import { Translate } from '@frontend/translation';
import { EmptySpace, Stage, StatusBar } from '@frontend/ui-elements';
import ArrowUpDownLineIcon from 'remixicon-react/ArrowUpDownLineIcon';
import {
  Column,
  DataWrapper,
  MainWrapper,
  OrderValue,
  OrderValueLabel,
} from './OrderCardDetails.css';
import { IOrderCardDetails } from './OrderCardDetails.types';
import { formatDateWithSpaces } from '../../../../../../app/shared/utils/date.utils';
import BillOfLadingDetails from './BillOfLadingDetails/BillOfLadingDetails';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import ContainerList from './ContainerList/ContainerList';
import MapPin2LineIcon from 'remixicon-react/MapPin2LineIcon';
import MoneyDollarCircleLineIcon from 'remixicon-react/MoneyDollarCircleLineIcon';
import OrderDocuments from '../../DocumentList/OrderDocuments/OrderDocuments';
import PricePill from './PricePill/PricePill';

export default function OrderCardDetails({
  order,
  refs: { documentsSectionRef, billOfLadingSectionRef, containerSectionRef },
}: IOrderCardDetails) {
  const { quantity, shippingTo, cost, createdDate } = order;

  return (
    <MainWrapper>
      <DataWrapper>
        <Column>
          <OrderValue aria-label="weight-info">
            <OrderValueLabel>
              <ArrowUpDownLineIcon size={18} />
              <Typography variant="p1" component="span">
                <Translate id="customer-platform.request-details.order-card.weight" />
              </Typography>
            </OrderValueLabel>
            <Typography variant="p3" component="span">
              {formatNumberToUS(quantity)}
              <EmptySpace />
              <Translate id="customer-platform.request-details.order-card.weight-unit" />
            </Typography>
          </OrderValue>
          <OrderValue aria-label="destination-info">
            <OrderValueLabel>
              <MapPin2LineIcon size={18} />
              <Typography variant="p1" component="span">
                <Translate id="customer-platform.request-details.order-card.destination" />
              </Typography>
            </OrderValueLabel>
            <Typography variant="p3" component="span">
              {shippingTo}
            </Typography>
          </OrderValue>
        </Column>

        <Column>
          <OrderValue aria-label="date-info">
            <OrderValueLabel>
              <Calendar2LineIcon size={18} />
              <Typography variant="p1" component="span">
                <Translate id="customer-platform.request-details.order-card.date" />
              </Typography>
            </OrderValueLabel>
            <Typography variant="p3" component="span">
              {formatDateWithSpaces(createdDate)}
            </Typography>
          </OrderValue>
          <OrderValue aria-label="price-info">
            {cost ? (
              <>
                <OrderValueLabel>
                  <MoneyDollarCircleLineIcon size={18} />
                  <Typography variant="p1" component="span">
                    <Translate id="customer-platform.request-details.order-card.price" />
                  </Typography>
                </OrderValueLabel>
                <Typography variant="p3" component="span">
                  {formatNumberToUS(cost)}
                  <EmptySpace />
                  <Translate id="customer-platform.request-details.order-card.price-unit" />
                </Typography>
              </>
            ) : (
              <PricePill />
            )}
          </OrderValue>
        </Column>
      </DataWrapper>
      <BillOfLadingDetails
        order={order}
        billOfLadingSectionRef={billOfLadingSectionRef}
      />
      <OrderDocuments order={order} documentsSectionRef={documentsSectionRef} />
      <ContainerList order={order} containerSectionRef={containerSectionRef} />
    </MainWrapper>
  );
}
