import { styled, theme } from '@frontend/theme';
import { accordionSummaryClasses, Box as MUIBox } from '@mui/material';
import { AccordionSummary as AccordionSummaryLib } from '@frontend/ui-elements';

export const OrderWrapper = styled(MUIBox)(() => ({
  border: `1px solid ${theme.palette.primary[50]}`,
  padding: '20px 20px',
  [theme.breakpoints.down('tablet')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const AccordionSummary = styled(AccordionSummaryLib)(() => ({
  '& > .MuiAccordionSummary-content': {
    flexDirection: 'column',
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    alignSelf: 'flex-start',
  },
}));

export const OrderMobileWrapper = styled(MUIBox)(() => ({
  padding: '0',
}));
