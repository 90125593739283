import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';
import { IMainWrapper } from './StatusPill.types';

const getBackgroundColor = ({ theme, status }: IMainWrapper) => {
  switch (status) {
    case 'request.status.pending':
    case 'listing.status.pending':
      return theme?.palette.tertiary[500];
    case 'request.status.in_progress':
    case 'listing.status.confirming_details':
    case 'listing.status.sourcing':
      return theme?.palette.secondary[600];
    case 'request.status.cancelled':
    case 'listing.status.cancelled':
      return theme?.palette.neutral[400];
    case 'request.status.accepted':
    case 'listing.status.accepted':
      return theme?.palette.primary[500];
  }
};

const getTextColor = ({ theme, status }: IMainWrapper) => {
  switch (status) {
    case 'request.status.pending':
    case 'request.status.in_progress':
    case 'listing.status.pending':
    case 'listing.status.confirming_details':
    case 'listing.status.sourcing':
      return theme?.palette.neutral[500];
    case 'request.status.accepted':
    case 'request.status.cancelled':
    case 'listing.status.accepted':
    case 'listing.status.cancelled':
      return theme?.palette.common.white;
  }
};

export const MainWrapper = styled(MUIBox, {
  shouldForwardProp: prop => prop !== 'status',
})(({ theme, status }: IMainWrapper) => ({
  display: 'inline-flex',
  gap: '4px',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  color: getTextColor({ theme, status }),
  backgroundColor: getBackgroundColor({ theme, status }),
  padding: '4px 8px',
  width: 'auto',
}));
