import { useRef } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { useMediaQuery, useTheme } from '@mui/material';
import { useGetDistanceToTop } from '../../../../app/shared/utils/useGetDistanceToTop/useGetDistanceToTop';
import { ListingCardWrapper, ListingListWrapper } from './ListingList.css';
import ListingCard from '../../ListingCard/ListingCard';
import { IListing } from '@frontend/api';
import { IListingList } from './Listings.types';

export default function ListingList({ listings }: IListingList) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const { uuid } = useParams();
  const navigate = useNavigate();
  const refHTMLElement = useRef<HTMLDivElement>(null);
  const distanceToTop = useGetDistanceToTop({ elementRef: refHTMLElement });

  const handleClick = (clickedListingUuid: string) => () => {
    const firstListingDetailsPage = generatePath(
      PLATFORM_ROUTES.listingDetails,
      { uuid: clickedListingUuid },
    );
    navigate(firstListingDetailsPage, { replace: true });
  };

  return (
    <ListingListWrapper ref={refHTMLElement} distance={distanceToTop}>
      {listings.map((listing: IListing) => (
        <ListingCardWrapper key={listing.uuid}>
          <ListingCard
            listing={listing}
            expanded={isDesktop ? listing.uuid === uuid : true}
            onClick={handleClick(listing.uuid)}
          />
        </ListingCardWrapper>
      ))}
    </ListingListWrapper>
  );
}
