import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { formatNumberToUS } from '@frontend/utils';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { Button, EmptySpace } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import {
  DataWrapper,
  DetailsItem,
  ImageWrapper,
  MainWrapper,
} from './ContainerCard.css';
import { ContainerCardProps, ItemProps } from './ContainerCard.types';
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon';
import ContainerDrawer from './ContainerDrawer/ContainerDrawer';
import ContainerImage from './container.svg';
import { isNull } from 'lodash';

const isValueMissing = (value: string | null | number) => {
  return isNull(value) || value === '';
};

export default function ContainerCard({
  container,
  orderId,
}: ContainerCardProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { uuid: requestId } = useParams();

  const {
    uuid: containerId,
    containerNumber,
    sealNumber,
    netWeight,
    tare,
    total,
  } = container;

  const handleContainerDrawerOpen = () => {
    const containerDetailsUrl = generatePath(PLATFORM_ROUTES.containerDetails, {
      uuid: requestId as string,
      orderId: orderId as string,
      containerId: containerId as string,
    });
    navigate(containerDetailsUrl, { replace: true });
  };

  const Item = ({ titleTranslationId, value, hasWeightUnit }: ItemProps) => {
    const Unit = () => {
      if (hasWeightUnit && !isValueMissing(value)) {
        return (
          <>
            <EmptySpace />
            <Translate id="customer-platform.request-details.order-card.weight-unit" />
          </>
        );
      }
      return null;
    };

    return (
      <DetailsItem>
        <Typography variant="p1" component="span" noWrap>
          <Translate id={titleTranslationId} />
        </Typography>
        <Typography variant="p3" component="span">
          {isValueMissing(value) ? (
            <Translate id="customer-platform.request-details.order-list.container-list.container.not-available" />
          ) : (
            formatNumberToUS(value as number | string)
          )}
          <Unit />
        </Typography>
      </DetailsItem>
    );
  };

  return (
    <MainWrapper data-testid="container-card">
      <ImageWrapper>
        <img
          srcSet={ContainerImage}
          src={ContainerImage}
          alt={intl.formatMessage({
            id: 'customer-platform.request-details.order-list.container-list.container.image-alt',
          })}
          loading="lazy"
        />
      </ImageWrapper>
      <DataWrapper>
        <Item
          titleTranslationId="customer-platform.request-details.order-list.container-list.container.container-number"
          value={containerNumber}
        />
        <Item
          titleTranslationId="customer-platform.request-details.order-list.container-list.container.seal-number"
          value={sealNumber}
        />
        <Item
          titleTranslationId="customer-platform.request-details.order-list.container-list.container.net-weight"
          value={netWeight}
          hasWeightUnit
        />
        <Item
          titleTranslationId="customer-platform.request-details.order-list.container-list.container.tare"
          value={tare}
          hasWeightUnit
        />
        <Item
          titleTranslationId="customer-platform.request-details.order-list.container-list.container.total"
          value={total}
          hasWeightUnit
        />
      </DataWrapper>
      <ContainerDrawer containerId={containerId} orderId={orderId} />
      <Button
        buttonType="primary"
        endIcon={<ArrowRightUpLineIcon size={24} />}
        id="container-card.button.open-container-drawer"
        data-testid="container-card-button"
        onClick={handleContainerDrawerOpen}
        size="large"
      >
        <Translate id="customer-platform.request-details.order-list.container-list.container.open-container-view" />
      </Button>
    </MainWrapper>
  );
}
