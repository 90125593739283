import { useGetListings } from '@frontend/api';
import { FullPageLoader, PageWrapper } from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import { PageContent } from './SellerDashboard.css';
import PlatformHeader from '../../app/shared/components/PlatformHeader/PlatformHeader';
import EmptyDashboard from '../../app/shared/components/EmptyDashboard/EmptyDashboard';
import { Navigate, generatePath } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import DashboardHeader from '../../app/shared/components/DashboardHeader/DashboardHeader';
import ListingList from './ListingDetails/ListingList/ListingList';
import { HEADER_VARIANTS } from '../../app/shared/components/PlatformHeader/PlatformHeader.types';

export default function SellerDashboard() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const { status: getListingListStatus, data: listingListResponse } =
    useGetListings();

  if (getListingListStatus === 'success') {
    if (!listingListResponse?.data.length) {
      return (
        <>
          <PlatformHeader variant={HEADER_VARIANTS.SELLER} />
          <PageWrapper>
            <EmptyDashboard />
          </PageWrapper>
        </>
      );
    }
    if (isDesktop) {
      const firstListing = listingListResponse?.data[0];
      const firstListingDetailsPage = generatePath(
        PLATFORM_ROUTES.listingDetails,
        { uuid: firstListing?.uuid },
      );
      return <Navigate to={firstListingDetailsPage} replace />;
    } else {
      return (
        <>
          <PlatformHeader variant={HEADER_VARIANTS.SELLER} />
          <PageWrapper>
            <PageContent>
              <DashboardHeader cardsCount={listingListResponse.data.length} />
              <ListingList listings={listingListResponse.data} />
            </PageContent>
          </PageWrapper>
        </>
      );
    }
  }

  if (getListingListStatus === 'pending') {
    return <FullPageLoader />;
  }
}
