import { useRef } from 'react';
import { Translate } from '@frontend/translation';
import { EmptySpace } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Container, ListWrapper, MainWrapper } from './OrderList.css';
import { IOrderList } from './OrderList.types';
import { useGetDistanceToTop } from '../../../../app/shared/utils/useGetDistanceToTop/useGetDistanceToTop';
import EmptyOrder from '../../../../app/shared/components/EmptyOrder/EmptyOrder';

export default function OrderList({ listing }: IOrderList) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const refHTMLElement = useRef<HTMLDivElement>(null);
  const distanceToTop = useGetDistanceToTop({ elementRef: refHTMLElement });

  return (
    <MainWrapper ref={refHTMLElement} distance={distanceToTop}>
      <Container>
        <Typography variant={isMobile ? 'h4' : 'h5'}>
          <Translate id="customer-platform.listing-details.order-list.order-listing-number" />
          <EmptySpace />
          {listing.listingNumberId}
        </Typography>
        <ListWrapper>
          <EmptyOrder />
          {/* TODO: order details implementation in future */}
        </ListWrapper>
      </Container>
    </MainWrapper>
  );
}
